import React from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list'
import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            item: this.props.item || null,
            group: null
        }

        // Bindings
        this.rowClick = this.rowClick.bind(this)

    }

    componentDidMount(){
        const { dispatch, match } = this.props
        if( match.params.id ){
            dispatch(gameActions.fetchGames(match.params.id))
        }
    }

    componentWillReceiveProps(newProps){ 
        this.setState({
            item: newProps.item
        });
    }

    rowClick( data, index ){
        this.setState({ group: data })
    }

    render(){

        const { game } = this.props;
        let groups = game.data.groups || [];
        groups = JSON.parse(JSON.stringify(groups))
        groups = groups.map( (p, pi) => {

            const zephyrRole = p.players.find( pl => pl.rindex === 1 )
            const fedeRole   = p.players.find( pl => pl.rindex === 0 )

            const accepted  = p.items.find( i => i.status === 'accepted' )

            p.group         = { value: pi +1, label: <button className="btn btn-outline-primary" style={{ lineHeight: '1' }}>{pi + 1}</button> }
            p.email         = { value: p.players[0].email, label: p.players.map( pl => <span key={`pl-${pl._id}`} className="d-block">{pl.email}</span> ) }
            
            if( accepted )
                p.amount     = { label: numeral(accepted.amount).format('0,0'), value: accepted.amount }
            else
                p.amount     = { label: p.status === 'nodeal' ? 'No Deal' : '-', value: p.status === 'nodeal' ? 'No Deal' : '-' }

            p.e_satisfaction = zephyrRole && zephyrRole.satisfaction || '-'
            p.g_satisfaction = fedeRole && fedeRole.satisfaction || '-'
            
            p.terms          = !accepted ? '-' : (
                accepted.terms.length > 0 
                ? <div className="text-left">
                    <b>{ accepted.author_role === 0 ? "Federico's" : 'Zephyr' }</b> <small>({ accepted.author_role === 0 ? fedeRole.email : zephyrRole.email })</small>: {accepted.terms}
                </div>
                : 'No'
            )

            return p;

        })

        groups = groups.filter( g => g )
        // console.log(this.props)

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                Leaderboard
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div style={{ fontSize: '14px' }}>
                <List 
                    labels={['Pair', 'Email', <span style={{ whiteSpace: 'nowrap' }}>Price ($)</span>, 'Additional Terms', <span style={{ whiteSpace: 'nowrap' }}>Zephyr Satisfaction</span>, <span style={{ whiteSpace: 'nowrap' }}>Federico's Satisfaction</span>]}
                    fields={['group', 'email', 'amount', 'terms', 'e_satisfaction', 'g_satisfaction']}
                    sortables={['group', 'email', 'amount', 'terms', 'e_satisfaction', 'g_satisfaction']}
                    data={ groups }
                    onRowClick={ this.rowClick }
                    />
            </div>

            <Modal isOpen={ this.state.group ? true : false } 
                centered={true} 
                size="xxl"
                toggle={ () => this.setState({ group: null }) }>
                <ModalBody className="text-center">
                    <FontAwesomeIcon 
                        icon={ faTimesCircle } 
                        size="2x"
                        className="icon bg-white text-danger"
                        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', borderRadius: '50%' }}
                        onClick={() => this.setState({ group: null })} />
                    { this.state.group && <GroupDebrief group={ this.state.group } /> }
                </ModalBody>
            </Modal>
            
        </div>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
