import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Header from '../../components/Header';
import CSVDrop from '../../components/CSVDrop';
import csv_template from '../../assets/simcase_csv_template.csv';
import playersDemo from '../../assets/players_roles_demo.png';

// Actions
import { gameActions } from '../../actions';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboarding: true,
      item: this.props.game.data || {},
      changed: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.launch = this.launch.bind(this);
    this.readCSV = this.readCSV.bind(this);
  }

  // componentDidMount(){
  //     if(this.props.game.data){
  //         this.setState({ item: this.props.game.data });
  //     }
  // }

  componentWillReceiveProps(newProps) {
    // Everytime REDUX updates the store
    // This hook will get executed and the state will get updated
    console.log({ newProps });
    this.setState({ item: newProps.game.data });
  }

  // componentDidUpdate(prevProps, prevState){
  //     console.log(prevProps, this.props)
  // }

  handleChange(e) {
    let item = { ...this.state.item };
    item[e.target.name] =
      e.target.name === 'players_text'
        ? e.target.value.replace(/\t/g, ', ')
        : e.target.value;
    item['changed'] = true;
    this.setState({ item });
  }

  submit() {
    this.props.dispatch(gameActions.updateGame(this.state.item));
  }

  launch() {
    this.props.dispatch(gameActions.launchGame(this.state.item));
  }

  readCSV(content) {
    let item = { ...this.state.item };
    item.players_text = content;
    this.props.dispatch(gameActions.updateGame(item));
  }

  render() {
    const { item, onboarding } = this.state;

    const okPPG = item.registered.length % item.ppg === 0 ? true : false;

    if (item.status === 'launched')
      return <Redirect to={'/games/' + item._id + '/players'} />;

    return (
      <div className=''>
        <Header />

        <h2 className='mt-3 sec-title' style={{ lineHeight: 1.3 }}>
          Simulation Setup{' '}
          <small
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle',
            }}
            className={
              item.status === 'saving'
                ? 'text-capitalize badge badge-pill badge-warning'
                : 'text-capitalize badge badge-pill badge-success'
            }>
            {item.status === 'saving' ? 'saving...' : item.status}
          </small>
          <div className='float-right' style={{ lineHeight: 1 }}>
            <button
              className='btn btn-md btn-link text-dark mr-5'
              onClick={() => this.setState({ onboarding: true })}>
              <small className='font-italic'>Need help?</small>
            </button>
            <button
              className='btn btn-md btn-outline-primary'
              onClick={this.submit}
              disabled={item.status === 'saving' ? true : false}>
              Save
            </button>
            <button
              className='btn btn-md btn-outline-success ml-3'
              onClick={this.launch}
              disabled={
                item.status === 'saving' || item.changed || !okPPG
                  ? true
                  : false
              }>
              Launch
            </button>
          </div>
        </h2>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-8'>
                <div className='form-group'>
                  <label>Game Name</label>
                  <div className='input-group input-group-lg'>
                    <input
                      name='name'
                      className='form-control'
                      placeholder='Enter game name'
                      disabled={item.status === 'saving' ? true : false}
                      value={item.name || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='form-group'>
                  <label>Access Type</label>
                  <div className='input-group input-group-lg'>
                    <select
                      name='access_type'
                      className='form-control'
                      value={item.access_type || ''}
                      onChange={this.handleChange}
                      disabled={item.status === 'saving' ? true : false}>
                      <option value='csv'>CSV</option>
                      {/* <option value="link">Link + Email</option> */}
                    </select>
                  </div>
                </div>
              </div>

              {item.access_type === 'link' && (
                <div className='col-12 mt-4'>
                  <div className='card'>
                    <div className='card-body bg-dark text-center pt-5 pb-5 pl-5 pr-5'>
                      <code style={{ fontSize: '2rem' }}>{item.short_url}</code>
                    </div>
                  </div>
                </div>
              )}

              {item.access_type === 'csv' && (
                <div className='cok-12'>
                  <div className='pt-3 pb-5 pl-5 pr-5'>
                    <ol>
                      <li className='mb-3'>
                        Structure your class list in a spreadsheet as follows
                        (click{' '}
                        <b>
                          <a href={csv_template} target='_blank'>
                            here
                          </a>
                        </b>{' '}
                        to download a copy):
                        <table className='table table-sm table-bordered table-striped mt-3'>
                          <tbody>
                            <tr>
                              <td>player1@email.com</td>
                              <td>Name</td>
                              <td>Surname</td>
                            </tr>
                            <tr>
                              <td>player2@email.com</td>
                              <td>Name</td>
                              <td>Surname</td>
                            </tr>
                            <tr>
                              <td>player3@email.com</td>
                              <td>Name</td>
                              <td>Surname</td>
                            </tr>
                            <tr>
                              <td>player4@email.com</td>
                              <td>Name</td>
                              <td>Surname</td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <p>
                          <b>Note</b>: The roles in this game are assigned in
                          the following order:
                        </p>
                        The first email in the list → Federico's
                        <br />
                        The second email in the list → Zephyr
                        <br />
                        The third email in the list → Federico's <br />
                        The fourth email in the list → Zephyr */}
                      </li>
                      <li className='mb-3'>
                        Copy and paste your data into the 'Registered Players'
                        text area at right.
                      </li>
                      <li className='mb-0'>
                        Click 'Save', verify the emails and names, then click
                        'Launch'.
                      </li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='form-group'>
              <label>
                Registered Players{' '}
                <span className='badge badge-warning badge-pill'>
                  {item.registered ? item.registered.length : 0}
                </span>
              </label>

              {item.registered.length % item.ppg !== 0 && (
                <small
                  style={{
                    fontSize: '12px',
                    padding: '5px 8px',
                    verticalAlign: 'middle',
                  }}
                  className='badge badge-pill badge-danger float-right'>
                  This game needs {item.ppg} players per group. Add more
                  players.
                </small>
              )}

              {item.access_type === 'csv' && (
                <CSVDrop onComplete={this.readCSV}>
                  <div className='input-group input-group-lg'>
                    <textarea
                      name='players_text'
                      className='form-control'
                      placeholder='email@example.com'
                      value={item.players_text || ''}
                      onChange={this.handleChange}
                      rows='15'
                      disabled={
                        item.status === 'saving' ? true : false
                      }></textarea>
                  </div>
                  <small>
                    <b>Tip</b>: You can also drag and drop your csv file here.
                  </small>
                </CSVDrop>
              )}

              {item.access_type === 'link' && (
                <ul class='list-group'>
                  {item.registered.map((rp) => (
                    <li class='list-group-item'>{rp.email}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        <Modal
          isOpen={onboarding}
          toggle={() => this.setState({ onboarding: !onboarding })}
          centered={true}
          size='lg'>
          <ModalHeader>Onboarding</ModalHeader>
          <ModalBody className='text-center'>
            <iframe
              src='https://player.vimeo.com/video/439382598'
              width='640'
              height='360'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullscreen></iframe>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-outline-primary'
              onClick={() => this.setState({ onboarding: !onboarding })}>
              Continue
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameForm };
