import React from 'react'
import numeral from 'numeral';


// The Header creates links that can be used to navigate
// between routes.
class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){

        const { items, players } = this.props.group;
        const accepted = items.find( i => i.status === 'accepted' )
        const zephyr = players.find( p => p.rindex === 1 )
        const fede = players.find( p => p.rindex === 0 )

        return <div>
            <div className="row mt-3">
                <div className="col-12 text-center">
                    <p className="mb-1">Final Offer</p>
                    <h3>{ accepted ? numeral(accepted.amount).format('$0,0') : '-' }</h3>
                </div>
                <div className="col-6 text-center mt-4">
                    <p className="mb-1">Zephyr<br/>Satisfaction</p>
                    <h3>{ zephyr.satisfaction || 'Pending'  }</h3>
                </div>
                <div className="col-6 text-center mt-4">
                    <p className="mb-1">Federico's<br/>Satisfaction</p>
                    <h3>{ fede.satisfaction || 'Pending'  }</h3>
                </div>
            </div>
        </div>
    }

}

export default Summary