import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class Debrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Debrief
          <small class='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3 pb-2 row'>
          <div className='col-12'>
            <div className='row bg-white mb-3 pt-5 pb-3'>
              <div className='col-6 offset-3 mb-4'>
                <p className='lead mt-1 pb-3 text-center'>
                  Students play the role of a Sales Manager participating in an
                  RFP process. After discussing the RFP with the customer, they
                  have been asked to submit one of the following three
                  proposals:
                </p>
                <hr />
              </div>

              <div className='col-4'>
                <div className='pl-5 pr-5'>
                  <h5>Proposal 1</h5>
                  <p className='mt-3'>
                    Continue 2% year over year price reductions and focus on
                    leaning out PPG production and logistics to improve profit
                    margins.
                  </p>
                </div>
              </div>
              <div className='col-4'>
                <div className='pl-5 pr-5'>
                  <h5>Proposal 2</h5>
                  <p className='mt-3'>
                    Fixed price with delivery and/or product performance
                    guarantees and focus on improving delivery and product
                    performance.
                  </p>
                </div>
              </div>
              <div className='col-4'>
                <div className='pl-5 pr-5'>
                  <h5>Proposal 3</h5>
                  <p className='mt-3'>
                    Sell paid value-added services while maintaining existing
                    pricing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Debrief);
export { connectedGamesPage as GameDebrief };
