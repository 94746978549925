import React from 'react'
import numeral from 'numeral';

class NumericInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value || ''
        }
        // Bindings 
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {}

    // componentDidUpdate(prevProps) {

    //     let edit = false;
    //     let newState = {};

    //     if (this.props.value !== prevProps.value){
    //         newState['value'] = this.props.value;
    //         edit = true;
    //     }

    //     if( edit )
    //         this.setState(newState)

    // }

    handleChange(e){
        let value = e.target.value
        value = value.replace(/,/g, '')
        value = parseInt(value)
        this.setState({ value: isNaN(value) ? '' : numeral(value).format('0,0') })
        this.props.onChange && this.props.onChange(value)
    }

    render() {
        return <input 
            {...this.props} 
            type="text"
            value={ this.state.value }
            onChange={ this.handleChange }
            />
    }

}
export default NumericInput