import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// Actions
import { gameActions } from '../../actions';
import { setupSocket, enterBackend, socketOn } from '../../helpers/sockets';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

const nodes = ['Anything else'];
const entities = [
  'BasicAttributes',
  'Importance',
  'Collaborators',
  'Comparison',
  'Satisfaction',
  'Action',
  'RFP',
];
const entitiesOther = ['sys-person'];
// Basic
const basicIntents = ['Satisfaction'];
// Other
const otherIntents = ['Hello', 'Goodbye', 'Introduction', 'SmallTalk'];

// Watson's response clasification
const responseClassification = (firstIntent, allEntities) => {
  // console.log(
  //   firstIntent,
  //   '|',
  //   allEntities.map((eo) => `${eo.entity}:${eo.value}`).join(', ')
  // );

  const tags = [];

  // Validate: "Other" entity?
  const hasOtherEntity = allEntities.some((entityObj) =>
    ['Hello', 'hello'].includes(entityObj.entity)
  );

  // Validate: Value Based entity?
  const hasValueEntity = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return ['Services', 'Goal', 'Switch', 'Assessment', 'State'].includes(
      entityObj.entity
    );
  });
  // Validate: @collaborators entity but different than the PM?
  const isCollabNoPM = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return (
      entityObj.entity === 'Collaborators' &&
      entityObj.value !== 'Procurement Manager'
    );
  });
  // Validate: Basic entities?
  const hasBasicEntity = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return ['Price'].includes(entityObj.entity);
  });
  const isActionPay = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return entityObj.entity === 'Action' && entityObj.value === 'Pay';
  });

  // Classification & Tagging
  if (firstIntent === 'Other' || hasOtherEntity) {
    console.log('First intent is Other or has "Other" entity', hasOtherEntity);
    tags.push('Other');
  } else if (hasValueEntity || isCollabNoPM) {
    console.log({ hasValueEntity, isCollabNoPM });
    tags.push('Value-Based');
  } else if (hasBasicEntity || isActionPay) {
    console.log({ hasBasicEntity, isActionPay });
    tags.push('Basic');
  } else {
    console.log('Fallback');
    tags.push('Basic');
  }

  return tags;
};

class DebriefProposals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_player: null,
      proposals: [],
    };

    // Bindings
    this.setProposals = this.setProposals.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      // dispatch(gameActions.fetchGames(match.params.id))
      fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setupSocket(data.io_domain, () => {
            enterBackend({ game_id: match.params.id }, (socketGameData) => {
              console.log({ socketGameData });
            });
            // Sockets!
            socketOn('offer', (m) => {
              dispatch(gameActions.fetchGames(match.params.id));
            }); // socketOn('nodeal', (m) => console.log(m) );
          });
        });
    }
  }

  setProposals(proposals) {
    this.setState({ proposals });
  }

  render() {
    const { game } = this.props;
    const { selected_player } = this.state;
    if (!game.data) return null;

    // Proposals data
    const proposals1 = game.data.results.filter(function (r) {
      return r.proposal == 1;
    });
    const proposals2 = game.data.results.filter(function (r) {
      return r.proposal == 2;
    });
    const proposals3 = game.data.results.filter(function (r) {
      return r.proposal == 3;
    });

    // Discussion
    const thePlayer = selected_player
      ? game.data.players.find((pl) => pl._id === selected_player)
      : null;
    const timelineEls = thePlayer
      ? thePlayer.chat.map((o, oi) => {
          //   console.log(o);
          //   const roles = ["Federico's", 'Zephyr'];

          const mine = thePlayer.email === o.from ? true : false;
          const authorName = mine ? thePlayer.name || thePlayer.email : 'David';
          let roleAlign = mine
            ? 'text-right text-kellogg d-inline-block'
            : 'text-left d-inline-block';
          let roleBox = mine
            ? 'float-right text-right bg-kellogg d-inline-block'
            : 'float-left d-inline-block bg-white';
          const roleName = mine ? 'You' : 'Purchasing Manager';

          roleAlign = !o.from ? 'text-center' : roleAlign;
          roleBox = !o.from ? 'bg-white' : roleBox;

          // Chat validations
          let uniqueTags = [];
          const c = o;
          if (o.wdata) {
            const tags = [];

            // Validating "Anything else" against nodes_visited
            const output = c.wdata.output || c.wdata.debug;
            const valueBasedNode = output.nodes_visited.some(function (n) {
              return nodes.indexOf(n) > -1;
            });
            if (valueBasedNode) {
              tags.push('Irrelevant');

              // Ok, let's decide if it is basic, other or value-based
            } else {
              // Get the 1st intent (the most relevant?)
              const firstIntent = c.wdata.intents[0]
                ? c.wdata.intents[0].intent || ''
                : '';

              // Get the entities
              const allEntities = c.wdata.entities;
              // Calculate the new tags
              const newTags = responseClassification(firstIntent, allEntities);
              tags.concat(newTags);
            }

            uniqueTags = tags.filter(function (item, pos) {
              return tags.indexOf(item) == pos;
            });
          }

          // console.log({ uniqueTags });

          return (
            <div key={`m-${oi}`} className='clearfix message'>
              {uniqueTags.map((t) => (
                <span
                  key={`t-${t}`}
                  className='badge badge-success float-right'>
                  {t}
                </span>
              ))}
              <div
                className={'card mb-3 ' + roleBox}
                style={{ minWidth: '60%', maxWidth: '80%' }}>
                <div className={roleAlign} style={{ padding: '10px 15px' }}>
                  {o.from && (
                    <small>
                      <b>
                        {authorName} ({roleName})
                      </b>
                    </small>
                  )}
                  {o.from && <br />}
                  {o.msg}
                </div>
              </div>
            </div>
          );
        })
      : [];

    // COMPOSITION
    // Get players for each proposals group
    var prop1Players = proposals1.map(function (prop) {
      return game.data.players.find(function (p) {
        return p.email == prop.player;
      });
    });
    var prop2Players = proposals2.map(function (prop) {
      return game.data.players.find(function (p) {
        return p.email == prop.player;
      });
    });
    var prop3Players = proposals3.map(function (prop) {
      return game.data.players.find(function (p) {
        return p.email == prop.player;
      });
    });

    var classTags1 = { basic: [], value: [], other: [], irrelevant: [] };
    prop1Players.forEach(function (player) {
      console.log(player.email);
      if (!player.chat) return;
      player.chat.forEach(function (c) {
        if (c.wdata) {
          const output = c.wdata.output || c.wdata.debug;
          // Validating "Anything else" against nodes_visited
          var valueBasedNode = output.nodes_visited.some(function (n) {
            return nodes.indexOf(n) > -1;
          });
          if (valueBasedNode) {
            classTags1.irrelevant.push('Anything else');

            // Ok, let's decide if it is basic, other or value-based
          } else {
            // This intent should fall into OTHER or BASIC
            // Both arrays contain unique strings
            var firstIntent = c.wdata.intents[0]
              ? c.wdata.intents[0].intent || ''
              : '';

            // Get the entities
            const allEntities = c.wdata.entities;
            // Calculate the new tags
            const newTags = responseClassification(firstIntent, allEntities);
            // console.log({ newTags });

            // Validate if this message contains
            // intents categorized as "other"
            if (newTags.includes('Other'))
              return classTags1.other.push('Other');

            // Validate if this message contains
            // intents categorized as "basic"
            if (newTags.includes('Basic'))
              return classTags1.basic.push('Basic');

            if (newTags.includes('Value-Based'))
              return classTags1.value.push('Value-Based');
          }
        }
      });
    });

    var classTags2 = { basic: [], value: [], other: [], irrelevant: [] };
    prop2Players.forEach(function (player) {
      if (!player.chat) return;
      player.chat.forEach(function (c) {
        if (c.wdata) {
          const output = c.wdata.output || c.wdata.debug;
          // Validating "Anything else" against nodes_visited
          var valueBasedNode = output.nodes_visited.some(function (n) {
            return nodes.indexOf(n) > -1;
          });
          if (valueBasedNode) {
            classTags2.irrelevant.push('Anything else');

            // Ok, let's decide if it is basic, other or value-based
          } else {
            // This intent should fall into OTHER or BASIC
            // Both arrays contain unique strings
            var firstIntent = c.wdata.intents[0]
              ? c.wdata.intents[0].intent || ''
              : '';

            // Get the entities
            const allEntities = c.wdata.entities;
            // Calculate the new tags
            const newTags = responseClassification(firstIntent, allEntities);
            // console.log({ newTags });

            // Validate if this message contains
            // intents categorized as "other"
            if (newTags.includes('Other'))
              return classTags2.other.push('Other');

            // Validate if this message contains
            // intents categorized as "basic"
            if (newTags.includes('Basic'))
              return classTags2.basic.push('Basic');

            if (newTags.includes('Value-Based'))
              return classTags2.value.push('Value-Based');
          }
        }
      });
    });

    var classTags3 = { basic: [], value: [], other: [], irrelevant: [] };
    prop3Players.forEach(function (player) {
      if (!player.chat) return;
      player.chat.forEach(function (c) {
        if (c.wdata) {
          const output = c.wdata.output || c.wdata.debug;
          // Validating "Anything else" against nodes_visited
          var valueBasedNode = output.nodes_visited.some(function (n) {
            return nodes.indexOf(n) > -1;
          });
          if (valueBasedNode) {
            classTags3.irrelevant.push('Anything else');

            // Ok, let's decide if it is basic, other or value-based
          } else {
            // This intent should fall into OTHER or BASIC
            // Both arrays contain unique strings
            var firstIntent = c.wdata.intents[0]
              ? c.wdata.intents[0].intent || ''
              : '';

            // Get the entities
            const allEntities = c.wdata.entities;
            // Calculate the new tags
            const newTags = responseClassification(firstIntent, allEntities);
            // console.log({ newTags });

            // Validate if this message contains
            // intents categorized as "other"
            if (newTags.includes('Other'))
              return classTags3.other.push('Other');

            // Validate if this message contains
            // intents categorized as "basic"
            if (newTags.includes('Basic'))
              return classTags3.basic.push('Basic');

            if (newTags.includes('Value-Based'))
              return classTags3.value.push('Value-Based');
          }
        }
      });
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Proposals
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3 pb-2 row'>
          <div className='col-12'>
            <div className='row bg-white mb-3 pb-3'>
              <div className='col-4'>
                {classTags1.basic.length +
                  classTags1.value.length +
                  classTags1.other.length >
                0 ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'pie',
                        style: { fontSize: '14px' },
                        height: '300px',
                      },
                      credits: { enabled: false },
                      title: {
                        text:
                          '<b>' +
                          prop1Players.length +
                          '</b>' +
                          '<br/>Submission(s)<br/><div style="color:#888">Proposal #1</div>',
                        align: 'center',
                        verticalAlign: 'middle',
                        y: 40,
                      },
                      xAxis: {
                        labels: { style: { fontSize: '14px' } },
                      },
                      yAxis: {
                        labels: { style: { fontSize: '14px' } },
                        allowDecimals: false,
                        min: 0,
                        title: {
                          text: '',
                        },
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            enabled: true,
                            distance: -10,
                            style: {
                              fontWeight: 'bold',
                              color: 'white',
                            },
                          },
                          startAngle: -90,
                          endAngle: 90,
                          center: ['50%', '85%'],
                          size: '150%',
                        },
                      },
                      series: [
                        {
                          name: 'Proposal 1',
                          innerSize: '70%',
                          data: [
                            {
                              name: 'Basic Intents',
                              y: classTags1.basic.length,
                            },
                            {
                              name: 'Value-based Intents',
                              y: classTags1.value.length,
                            },
                            { name: 'Other', y: classTags1.other.length },
                          ],
                        },
                      ],
                    }}
                  />
                ) : (
                  <div style={{ height: '295px' }}>
                    <p className='pt-5'></p>
                    <p className='lead text-center pt-5'>
                      <b>No submissions</b>
                      <br />
                      Proposal #1
                    </p>
                  </div>
                )}
                <p className='text-center'>
                  <button
                    className='btn btn-link'
                    onClick={() => this.setProposals(proposals1)}>
                    Identify Learners
                  </button>
                </p>
                <div className='pl-5 pr-5'>
                  <i>
                    Continue 2% year over year price reductions and focus on
                    leaning out PPG production and logistics to improve profit
                    margins.
                  </i>
                  <p className='lead mt-3'>
                    The purchasing manager clearly communicates a focus on
                    price, and this proposal delivers against that request.
                    However, it creates a precedent that may be unprofitable if
                    your company is unable to generate its own cost savings.
                  </p>
                </div>
              </div>
              <div className='col-4'>
                {classTags2.basic.length +
                  classTags2.value.length +
                  classTags2.other.length >
                0 ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'pie',
                        style: { fontSize: '14px' },
                        height: '300px',
                      },
                      credits: { enabled: false },
                      title: {
                        text:
                          '<b>' +
                          prop2Players.length +
                          '</b>' +
                          '<br/>Submission(s)<br/><div style="color:#888">Proposal #2</div>',
                        align: 'center',
                        verticalAlign: 'middle',
                        y: 40,
                      },
                      xAxis: {
                        labels: { style: { fontSize: '14px' } },
                      },
                      yAxis: {
                        labels: { style: { fontSize: '14px' } },
                        allowDecimals: false,
                        min: 0,
                        title: {
                          text: '',
                        },
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            enabled: true,
                            distance: -10,
                            style: {
                              fontWeight: 'bold',
                              color: 'white',
                            },
                          },
                          startAngle: -90,
                          endAngle: 90,
                          center: ['50%', '85%'],
                          size: '150%',
                        },
                      },
                      series: [
                        {
                          name: 'Proposal 2',
                          innerSize: '70%',
                          data: [
                            {
                              name: 'Basic Intents',
                              y: classTags2.basic.length,
                            },
                            {
                              name: 'Value-based Intents',
                              y: classTags2.value.length,
                            },
                            { name: 'Other', y: classTags2.other.length },
                          ],
                        },
                      ],
                    }}
                  />
                ) : (
                  <div style={{ height: '295px' }}>
                    <p className='pt-5'></p>
                    <p className='lead text-center pt-5'>
                      <b>No submissions</b>
                      <br />
                      Proposal #2
                    </p>
                  </div>
                )}

                <p className='text-center'>
                  <button
                    className='btn btn-link'
                    onClick={() => this.setProposals(proposals2)}>
                    Identify Learners
                  </button>
                </p>

                <div className='pl-5 pr-5'>
                  <i>
                    Fixed price with delivery and/or product performance
                    guarantees and focus on improving delivery and product
                    performance.
                  </i>
                  <p className='lead mt-3'>
                    Focusing on delivery and product performance may represent
                    value to other stakeholders at the plant. However, it may
                    not offset competitive proposals that deliver against the
                    purchasing manager's core interest which is reducing price.
                  </p>
                </div>
              </div>
              <div className='col-4'>
                {classTags3.basic.length +
                  classTags3.value.length +
                  classTags3.other.length >
                0 ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'pie',
                        style: { fontSize: '14px' },
                        height: '300px',
                      },
                      credits: { enabled: false },
                      title: {
                        text:
                          '<b>' +
                          prop3Players.length +
                          '</b>' +
                          '<br/>Submission(s)<br/><div style="color:#888">Proposal #3</div>',
                        align: 'center',
                        verticalAlign: 'middle',
                        y: 40,
                      },
                      xAxis: {
                        labels: { style: { fontSize: '14px' } },
                      },
                      yAxis: {
                        labels: { style: { fontSize: '14px' } },
                        allowDecimals: false,
                        min: 0,
                        title: {
                          text: '',
                        },
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            enabled: true,
                            distance: -10,
                            style: {
                              fontWeight: 'bold',
                              color: 'white',
                            },
                          },
                          startAngle: -90,
                          endAngle: 90,
                          center: ['50%', '85%'],
                          size: '150%',
                        },
                      },
                      series: [
                        {
                          name: 'Proposal 3',
                          innerSize: '70%',
                          data: [
                            {
                              name: 'Basic Intents',
                              y: classTags3.basic.length,
                            },
                            {
                              name: 'Value-based Intents',
                              y: classTags3.value.length,
                            },
                            { name: 'Other', y: classTags3.other.length },
                          ],
                        },
                      ],
                    }}
                  />
                ) : (
                  <div style={{ height: '295px' }}>
                    <p className='pt-5'></p>
                    <p className='lead text-center pt-5'>
                      <b>No submissions</b>
                      <br />
                      Proposal #3
                    </p>
                  </div>
                )}

                <p className='text-center'>
                  <button
                    className='btn btn-link'
                    onClick={() => this.setProposals(proposals3)}>
                    Identify Learners
                  </button>
                </p>

                <div className='pl-5 pr-5'>
                  <i>
                    Sell paid value-added services while maintaining existing
                    pricing.
                  </i>
                  <p className='lead mt-3'>
                    The new fee services promise to improve the customer's
                    performance when using the paint in the manufacturing line.
                    While the proposal holds price steady, the total CarCo cost
                    is lower which promises to please various stakeholders.
                    PPG's margin on the new services is 4x paint margins.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.proposals.length > 0 ? true : false}
          centered={true}
          size='xxl'
          toggle={() =>
            this.setState({ proposals: [], selected_player: null })
          }>
          <ModalHeader
            toggle={() =>
              this.setState({ proposals: [], selected_player: null })
            }>
            Learners: Proposal #{this.state.proposals[0]?.proposal}
          </ModalHeader>
          <ModalBody className='text-center'>
            <div className='row'>
              <div className='col-3 text-left'>
                <h5 className='mb-4'>Learners</h5>
                {this.state.proposals.map((p, pi) => {
                  const pl = game.data.players.find(
                    (gp) => gp._id === p.player_id
                  );
                  const author = `${pl.name} ${pl.lastname}`.trim().length
                    ? `${pl.name} ${pl.lastname}`
                    : pl.email;
                  return (
                    <button
                      className='btn btn-outline-primary btn-block'
                      onClick={() =>
                        this.setState({ selected_player: pl._id })
                      }>
                      {author}
                    </button>
                  );
                })}
              </div>
              <div className='col-9 text-left'>
                <h5 className='mb-4'>Discussion</h5>
                <div style={{ height: '60vh', overflow: 'auto' }}>
                  <div className='card'>
                    <div className='card-body p-4'>
                      {selected_player ? (
                        timelineEls
                      ) : (
                        <p className='text-center mb-0 pt-5 pb-5 lead'>
                          Click on a student from the list.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefProposals);
