import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { gamesActions, gameActions } from '../../actions';

import Header from '../../components/Header';
import List from './list';

class GamesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };

    // Bindings
  }

  componentDidMount() {
    // this.apiFindAll()
    this.props.dispatch(gameActions.setData(null));
    this.props.dispatch(gamesActions.fetchGames());
  }

  deleteGame(item) {
    const { dispatch } = this.props;
    dispatch(gamesActions.removeGame(item));
  }

  render() {
    const { games } = this.props;
    const items = games.map((i) => {
      return {
        instructor: i.instructor ? i.instructor.email : '',
        name: i.name,
        status: i.status,
        num_players: i.players.length,
        created_at: moment(i.created_at).format('MMMM Do YYYY, h:mm:ss a'),
        actions: [
          i.status === 'saved' ? (
            <Link
              key={`edit-${i._id}`}
              to={'/games/' + i._id}
              className='btn btn-sm btn-outline-primary'>
              Add Players
            </Link>
          ) : (
            <Link
              key={`edit-${i._id}`}
              to={'/games/' + i._id + '/players'}
              className='btn btn-sm btn-outline-success'>
              In Progress
            </Link>
          ),
          <button
            key={`remove-${i._id}`}
            disabled={i.status === 'deleted' ? true : false}
            className={`btn btn-sm btn-light text-danger ml-3 ${
              i.status === 'deleted' ? 'disabled' : ''
            }`}
            onClick={(e) => this.deleteGame(i)}>
            <FontAwesomeIcon
              icon='trash-alt'
              size='1x'
              style={{ cursor: 'pointer' }}
            />
          </button>,
        ],
      };
    });

    return (
      <div>
        <Header />

        <h2 className='row sec-title'>Games</h2>

        <div>
          <List
            labels={[
              'Name',
              'Instructor',
              'Status',
              'Players',
              'Created At',
              'Actions',
            ]}
            fields={[
              'name',
              'instructor',
              'status',
              'num_players',
              'created_at',
              'actions',
            ]}
            sortables={[
              'name',
              'instructor',
              'status',
              'num_players',
              'created_at',
            ]}
            data={items}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, games } = state;
  return {
    player,
    games,
  };
}

const connectedGamesPage = connect(mapStateToProps)(GamesPage);
export { connectedGamesPage as GamesPage };
