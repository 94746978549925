import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Modal, ModalBody } from 'reactstrap';

// Actions
import { gameActions } from '../../actions';
import { setupSocket, enterBackend, socketOn } from '../../helpers/sockets';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

const nodes = ['Anything else'];
const entities = [
  'BasicAttributes',
  'Importance',
  'Collaborators',
  'Comparison',
  'Satisfaction',
  'Action',
  'RFP',
];
const entitiesOther = ['sys-person'];
// const classTags = { basic: [], value: [], other: [], irrelevant: [] };
// Basic
const basicIntents = ['Satisfaction'];
// Other
const otherIntents = ['Hello', 'Goodbye', 'Introduction', 'SmallTalk'];

// Watson's response clasification
const responseClassification = (firstIntent, allEntities) => {
  // console.log(
  //   firstIntent,
  //   '|',
  //   allEntities.map((eo) => `${eo.entity}:${eo.value}`).join(', ')
  // );

  const tags = [];

  // Validate: "Other" entity?
  const hasOtherEntity = allEntities.some((entityObj) =>
    ['Hello', 'hello'].includes(entityObj.entity)
  );

  // Validate: Value Based entity?
  const hasValueEntity = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return ['Services', 'Goal', 'Switch', 'Assessment', 'State'].includes(
      entityObj.entity
    );
  });
  // Validate: @collaborators entity but different than the PM?
  const isCollabNoPM = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return (
      entityObj.entity === 'Collaborators' &&
      entityObj.value !== 'Procurement Manager'
    );
  });
  // Validate: Basic entities?
  const hasBasicEntity = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return ['Price'].includes(entityObj.entity);
  });
  const isActionPay = allEntities.some((entityObj) => {
    // console.log(entityObj.entity, entityObj.value);
    return entityObj.entity === 'Action' && entityObj.value === 'Pay';
  });

  // Classification & Tagging
  if (firstIntent === 'Other' || hasOtherEntity) {
    console.log('First intent is Other or has "Other" entity', hasOtherEntity);
    tags.push('Other');
  } else if (hasValueEntity || isCollabNoPM) {
    console.log({ hasValueEntity, isCollabNoPM });
    tags.push('Value-Based');
  } else if (hasBasicEntity || isActionPay) {
    console.log({ hasBasicEntity, isActionPay });
    tags.push('Basic');
  } else {
    console.log('Fallback');
    tags.push('Basic');
  }

  return tags;
};

class DebriefAggregate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_player: null,
      proposals: [],
    };

    // Bindings
    this.setProposals = this.setProposals.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      // dispatch(gameActions.fetchGames(match.params.id))
      fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setupSocket(data.io_domain, () => {
            enterBackend({ game_id: match.params.id }, (socketGameData) => {
              console.log({ socketGameData });
            });
            // Sockets!
            socketOn('offer', (m) => {
              dispatch(gameActions.fetchGames(match.params.id));
            }); // socketOn('nodeal', (m) => console.log(m) );
          });
        });
    }
  }

  setProposals(proposals) {
    this.setState({ proposals });
  }

  render() {
    const { game } = this.props;
    if (!game.data) return null;

    const classTags = { basic: [], value: [], other: [], irrelevant: [] };

    // INTENTS: CHART DATA ALL
    game.data.players.forEach(function (players) {
      players.chat.forEach(function (c) {
        if (c.wdata) {
          const output = c.wdata.output || c.wdata.debug;
          // Validating "Anything else" against nodes_visited
          var valueBasedNode = output.nodes_visited.some(function (n) {
            return nodes.indexOf(n) > -1;
          });
          if (valueBasedNode) {
            classTags.irrelevant.push('Anything else');

            // Ok, let's decide if it is basic, other or value-based
          } else {
            // This intent should fall into OTHER or BASIC
            // Both arrays contain unique strings
            var firstIntent = c.wdata.intents[0]
              ? c.wdata.intents[0].intent || ''
              : '';

            // Get the entities
            const allEntities = c.wdata.entities;
            // Calculate the new tags
            const newTags = responseClassification(firstIntent, allEntities);
            // console.log({ newTags });

            // Validate if this message contains
            // intents categorized as "other"
            if (newTags.includes('Other')) return classTags.other.push('Other');

            // Validate if this message contains
            // intents categorized as "basic"
            if (newTags.includes('Basic')) return classTags.basic.push('Basic');

            if (newTags.includes('Value-Based'))
              return classTags.value.push('Value-Based');
          }
        }
      });
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Aggregate
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3 pb-2 row'>
          <div className='col-12'>
            <HighchartsReact
              containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
              highcharts={Highcharts}
              options={{
                chart: { type: 'column', style: { fontSize: '14px' } },
                credits: { enabled: false },
                title: { text: '' },
                xAxis: {
                  labels: { style: { fontSize: '14px' } },
                  categories: ['Basic', 'Value-Based', 'Other'],
                },
                yAxis: {
                  labels: { style: { fontSize: '14px' } },
                  allowDecimals: false,
                  min: 0,
                  title: { text: '' },
                },
                tooltip: {
                  style: { fontSize: '14px' },
                  formatter: function () {
                    if (this.point.category == 'Total')
                      return (
                        '<span style="color:{series.color}">' +
                        this.series.name +
                        '</span>: <b>' +
                        this.y +
                        '</b>'
                      );
                    else
                      return (
                        '<span style="color:{series.color}">' +
                        this.series.name +
                        '</span>: <b>' +
                        this.y +
                        '</b>'
                      );
                  },
                  //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>Click to see details.'
                },
                plotOptions: {
                  column: {
                    dataLabels: {
                      enabled: true,
                      crop: false,
                      overflow: 'none',
                    },
                  },
                },
                series: [
                  {
                    name: 'Count',
                    data: [
                      classTags.basic.length,
                      classTags.value.length,
                      classTags.other.length,
                    ],
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefAggregate);
